import React, { Component } from 'react';
import  {Container, Col, Row, Card, ListGroup, Table} from 'react-bootstrap';
import { BrowserRouter as Route,} from "react-router-dom";
import ServicesBlock from '../../Componets/ServicesBlock';
import Typography from '@mui/material/Typography';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBContainer } from 'mdb-react-ui-kit';
import { Helmet } from "react-helmet";
import RSY from '../../Componets/RSY';
const Services_Ремонт_станков_с_ЧПУ = (props)=> {
   
        return (
            <><Helmet>
            <meta charSet="utf-8" />
            <title>{props.titles}</title>
            <meta name="description" content={props.description}></meta>
          </Helmet>
          <Container>
                <Row className="mt-5">

                    <Col md="3" className="mt-2">
                        <Card>
                            <Card.Header className="text-center">Наши услуги</Card.Header>
                            <ListGroup variant="flush" striped bordered hover>
                                <ServicesBlock />
                                <RSY />
                            </ListGroup>
                        </Card>
                        {/* <Card className="mt-5">
                            <Card.Header className="text-center" ><h4>После ремонта</h4></Card.Header>
                            <Card.Body>
                                <blockquote className="blockquote mb-0">
                                    <Typography variant="body1" color="text.secondary">
                                    <img
                    className="img-fluid"
                    src="http://asan-pro.ru/images/03a353f6a2b942434042419fbe956e12.jpeg"
                    alt=""
                  />     
                                    </Typography>

                                </blockquote>
                            </Card.Body>
                        </Card> */}
                    </Col>
                    <Col md={9}>
                    <MDBCard>
            <MDBCardBody>
              <h3 className="font-weight-bold mb-3 p-0 text-center">
                <strong>{props.titles}</strong>
              </h3>
              <hr className="my-3" />
              <MDBRow>
                <MDBCol>
               
                <h4 className="font-weight-bold mb-3 p-0 text-center">
                <strong> Ремонт и диагностика станков с со2 излучателем и твердотельным лазером</strong>
              </h4>
                  <img
                    className="img-responsive img-right"
                    src="/img/servise/ad86070872e21eb873f95dace002b224.jpeg"
                    height="290px" width="220px" 
                    title="До ремонта"
                    alt={props.titles}
                  />
                  <p>
                  Лазерно-гравировальная мастерская АсАн предоставляет комплекс услуг по диагностике неисправностей фрезерно-гравировальных станков с ЧПУ и лазерно-гравировальных машин(СО2), твердотельных лазеров(кафедра лазерных машин). Подробную информацию об услуге вы можете уточнить у менеджеров нашей компании по тел.: 8-863-529-96-50 или оставить нам сообщение через форму обратной связи.
                  </p>
                  <p>Лазерно-гравировальные машины на CO2 и станки на твердотельных лазерах — чрезвычайно сложное оборудование, требующее внимательного отношения к эксплуатации. Если его обслуживание и ремонт доверять непрофессионалам, срок его работы может сократиться в разы. Учитывая цену такого оборудования, можно представить сумму убытков, которые понесет в результате такого подхода предприятие-владелец подобных машин. </p>
<p>Диагностика станков с ЧПУ, основанных на лазерных технологиях, включает диагностику специального высоковольтного блока питания, драйвера приводов, поиск поврежденной электронной платы. В нашей стране найти плату для замены очень непросто. </p>
<p>Ремонт и настройка лазерных станков с ЧПУ часто связаны с заменой линз, зеркал, лазерной трубки, ремонтом и подключением высоковольтного блока питания, заменой высоковольтного провода. Все перечисленные этапы представляют собой очень тонкую работу, ошибиться в которой может означать безвозвратную потерю высокотехнологичного устройства. </p>
<p>Ошибки в ремонте и настройке также могут привести к пожару. Вызов мастера для негарантийного ремонта после такого события и восстановление сгоревшего оборудования обойдутся фирме во многие десятки тысяч рублей. </p>
<img
                    className="img-responsive img-left"
                    src="/img/servise/03a353f6a2b942434042419fbe956e12.jpeg"
                    height="290px" width="220px" 
                    title="После ремонта"
                    alt={props.titles}
                  />
<p>Ремонт ЧПУ завершается юстировкой оптики. Эта процедура требует от мастера навыков, которые приобретаются лишь длительным опытом. </p>
<p>Работа по ремонту лазерного станка требует глубокого знания лазерной технологии, всех многочисленных ноу-хау, которые применяют при изготовлении подобного оборудования зарубежные производители. Существует мнение, что с этим справятся любые квалифицированные специалисты по электротехнике; достаточно лишь дать им ознакомиться с прилагаемой к станку документацией. </p>
<p>Однако в настоящее время производители перестали снабжать оборудование объемистым пакетом технических документов. Мотивируется это тем, что современные станки такого рода настолько совершенны, что при грамотном обслуживании поломаться просто не могут. В сложных же случаях в Европе и США всегда придет на помощь компания, выпустившая станок.</p>
<p>Диагностика, переналадка, профилактический ремонт даже идеально работающего оборудования подобного рода должны производить не реже, чем раз в полгода. В нашей компании вы можете получить помощь высококвалифицированных специалистов в данной области.</p>
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
                       
                    </Col>
                </Row>
            </Container></>
        );
    }

export default Services_Ремонт_станков_с_ЧПУ;