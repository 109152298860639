import React from "react";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./Componets/Header";
import Footer from './Componets/Footer';


const App = () =>{
  return (
    <div>
    <Header />
    <Footer />
    </div>
  );
}

export default App;