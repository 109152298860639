import React from 'react';
import { YMInitializer } from 'react-yandex-metrika';

const Metrika = () => {

  return (
    <div>

      <YMInitializer accounts={[22375891]} options={{ webvisor: true }} version="2" />

    </div>

  );

}
export default Metrika;